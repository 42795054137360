import React from 'react';
import Banner from '../../assets/images/about-banner.png';
import Section1 from '../../assets/images/about-section-1.png';
import BannerWrap from '../../components/BannerWrap';
import SectionWrap from '../../components/SectionWrap';

import * as styles from './index.module.less';
import { StaticImage } from 'gatsby-plugin-image';
import ContentWrap from '../../components/ContentWrap';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';

export default function AboutPage() {
  return (
    <Layout>
      <BannerWrap
        img={Banner}
        layout="center"
        title={
          <>
            <div>用创想与技术</div>
            <br />
            <div>推动中国云存储市场发展</div>
          </>
        }
      />
      <SectionWrap background={`url("${Section1}")`}>
        <div className={styles.comAbout}>
          <div className={styles.comAboutInfo}>
            <h1>公司简介</h1>
            <div>
              北京国科环宇科技股份有限公司成立于2004年，是一家自主可控的关键电子系统解决方案提供商。参与了载人航天工程、北斗卫星导航系统、高分辨率对地观测系统等国家重大专项。
              目前在北京、西安 、天津、上海 、成都、深圳、长沙等7个地区设立子公司和研发中心。
            </div>
          </div>
          <div className={styles.comCover}>
            <StaticImage
              width={350}
              style={{ maxWidth: 350 }}
              src="../../assets/images/about-company.png"
              alt=""
            />
          </div>
        </div>
      </SectionWrap>

      <ContentWrap className={styles.mapContainer}>
        <div>
          <StaticImage width={950} src="../../assets/images/about-map.png" alt="" />
        </div>
        <div className={styles.cardList}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>客户服务</div>
            <div>提供各类客户业务支持，包括产品咨询、技术支持建议反馈、以及购买帮助等</div>
            <div>
              电话：<a href="tel:4006405800">400-6405-800</a>
            </div>
            <div>
              邮箱：<a href="mailto:ucasscs@ucas.com.cn">ucasscs@ucas.com.cn</a>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>商务合作</div>
            <div>
              合作伙伴战略合作、数据存储解决方案合作、媒体合作、会议合作、渠道分销代理合作等合作洽谈
            </div>
            {/* <div>
              电话：<a href="tel:13716803033">137-1680-3033</a>
            </div> */}
            <div>
              邮箱：<a href="mailto:yongchao.fan@ucas.com.cn">wanglin@ucas.com.cn</a>
            </div>
          </div>
        </div>
      </ContentWrap>
    </Layout>
  );
}

export function Head() {
  return <SEO title="关于我们" />;
}
